import * as React from 'react';
import { BoundedContent, ScreenClassProvider, SkipLink } from '@vp/swan';
import { useBookendsData } from '../../hooks/useBookendsData';
import { TrackingProvider } from '../../hooks/useTracking';
import { MasksLayout } from './MasksLayout';
import { Image } from '../Image';
import { HelpLink } from './HelpLink';
import TrackableLink from '../TrackableLink';
import { ErrorBoundary } from '../ErrorBoundary';
import {
  BookendsResponse,
  GenericSharedProps,
  BookendsServiceCallParameters,
  SlimHeaderProps,
} from '../../types';

import LanguageBar from 'components/LanguageBar';
import { Logger } from '../../utils/logger';

type Props =
  | (BookendsResponse<SlimHeaderProps> & GenericSharedProps)
  | (BookendsServiceCallParameters & GenericSharedProps);

const SlimHeaderContents = (props: Props) => {
  const data = useBookendsData<SlimHeaderProps>(props, {
    version: 'v3',
    headerOrFooter: 'header',
    variant: 'slim',
  });

  if (!data) return <header></header>;

  // -- TODO : remove this temporary code later -- // 
  if (data.homeUrl?.includes('undefined')) {
    Logger.instance.info(`slim header homepage link contains undefined - ${data.homeUrl}`, {
      actualLogoElementURL: document.querySelector(".site-header-logo a")?.getAttribute("href"),
      bookendsKey: document?.getElementById("bookendsHeader")?.getAttribute("data-headerkey"),
    });
  }
  // -- TODO : remove this temporary code later -- // 

  const { inlineLogo, inlineSecondaryLogo, helpLink, carePhoneNumber } = data;

  if (data.headerLayoutName === 'microsite') {
    return <MasksLayout data={data} />;
  }

  return (
    <>
      <SkipLink>{data.skipToMainContent}</SkipLink>
      <header className="site-header site-header-slim">
        <LanguageBar data={data} />
        <BoundedContent>
          <div className="site-header-main site-header-main-slim full-width-container full-width-container-capped">
            <div className="site-header-logo-and-tagline">
              <div className="site-header-logo">
                <TrackableLink
                  href={data.homeUrl}
                  data-fidoprefetchhint={'EAGER'}
                  navigationDetailPath={['homePage']}
                  aria-label={data.logoDescription}
                >
                  <Image className={'logo-fullsize'} image={inlineLogo.image} alt='' />
                  <Image
                    className={'logo-narrow'}
                    image={inlineSecondaryLogo.image}
                  />
                </TrackableLink>
              </div>
            </div>
            <nav className="site-header-links site-header-link site-header-link-menu nohover swan-hidden-sm swan-hidden-xs">
              <HelpLink helpLink={helpLink} carePhoneNumber={carePhoneNumber} />
            </nav>
          </div>
        </BoundedContent>
      </header>
    </>
  );
};

export const SlimHeader = (props: Props) => {
  return (
    <TrackingProvider
      trackingConfiguration={props.trackingConfiguration}
      bookendName="Header"
    >
      <ErrorBoundary
        suppressError={props.suppressError}
        component="slim-header"
      >
        <ScreenClassProvider>
          <SlimHeaderContents {...props} />
        </ScreenClassProvider>
      </ErrorBoundary>
    </TrackingProvider>
  );
};
