import * as React from 'react';
import { Typography } from '@vp/swan';

import { Image } from '../Image';
import TrackableLink from '../TrackableLink';
import { HelpLinkProps } from '../../types';

export const HelpLink = (props: HelpLinkProps) => {
  const { helpLink: simpleLink } = props;
  return (
    <TrackableLink
      className="site-header-link site-header-link-content"
      href={simpleLink.url}
      navigationDetailPath={['helpPage']}
    >
      {simpleLink.image && (
        <Image className={'site-header-link-icon'} image={simpleLink.image} ariaHidden={true} />
      )}
      <Typography as='span' fontSize="small" className='site-header-link-text swan-text-1'>
        {simpleLink.text}
      </Typography>
    </TrackableLink>
  );
};
